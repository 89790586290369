import Button from "@/components/elements/Button";
import Heading from "@/components/elements/Heading";
import LinkComponent from "@/components/elements/LinkComponent";
import NewsletterForm from "@/components/elements/NewsletterForm";

import Icon from "@/components/elements/Icon";
import { whatYouGet } from "./data/what-you-get";
import * as styles from "./styles.module.scss";

export function Newsletter({
  actions,
  heading,
  subheading,
}: Pick<NewsletterSectionType, "actions" | "heading" | "subheading">) {
  return (
    <section className={styles.newsletterSection}>
      <div className="container">
        <div className={styles.descriptionColumn}>
          <Heading level={2}>
            {heading || "Subscribe to our newsletter"}
          </Heading>
          <p>
            {subheading ||
              "Don't miss out on the latest research on plant-based eating and updates from Alpro Foundation."}
          </p>
          <ul>
            <h5>What do you get?</h5>
            {Array.isArray(whatYouGet) &&
              whatYouGet.map((item, index) => (
                <li key={`item-${index}`}>
                  <span>
                    <Icon name={item.icon} className={styles.icon} />
                    <p>{item.text}</p>
                  </span>
                </li>
              ))}

            {!Array.isArray(actions) && (
              <Button variant="outlined">
                <LinkComponent url="/privacy-policy" type="internal" with_icon>
                  Privacy Policy
                </LinkComponent>
              </Button>
            )}
          </ul>
          {Array.isArray(actions) &&
            actions.map((item) => (
              <Button variant="outlined" key={item._uid}>
                <LinkComponent
                  url={item.url}
                  type={item.type}
                  with_icon={item.with_icon}
                >
                  {item.title}
                </LinkComponent>
              </Button>
            ))}
        </div>
        <NewsletterForm />
      </div>
    </section>
  );
}
